




































import Vue from 'vue';
import Component from 'vue-class-component';
import {EmailResource, JsonApiFilters} from '@/interfaces';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import {Prop} from 'vue-property-decorator';
import {emailModule} from '@/store/modules/emailModule';

@Component({
  components: {
    CreatedModifiedCell,
  },
})
export default class EmailSentList extends Vue {
  @Prop({default: () => ({})}) private filters!: JsonApiFilters;
  private search = '';
  private headers = [
    {text: 'Sent Date', value: 'createdModified'},
    {text: 'Recipients', value: 'attributes.recipients'},
    {text: 'Subject', value: 'attributes.subject'},
    {text: 'From', value: 'attributes.from'},
    // {text: 'Message ID', value: 'attributes.messageId'},
  ];

  get emails(): EmailResource[] {
    return emailModule.list;
  }

  get loading(): boolean {
    return emailModule.listing;
  }

  async created() {
    emailModule.reset();
    await emailModule.loadMulti();
  }
}
