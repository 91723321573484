







import Vue from 'vue';
import Component from 'vue-class-component';
import PageTitle from '@/components/ui/PageTitle.vue';
import Page from '@/components/ui/Page.vue';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import SentEmailList from '@/components/sent-emails/SentEmailList.vue';

@Component({
  components: {
    SentEmailList,
    CreatedModifiedCell,
    Page,
    PageTitle,
  },
})
export default class SentEmails extends Vue {}
